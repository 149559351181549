// build-in packages
import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, firstValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";

// services
import { SharedService } from "src/app/services/shared.service";
import { ClustersService } from "../../clusters/clusters.service";
import { ChannelsService } from "../channels.service";
import { SourcesService } from "../../sources/sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { TitleService } from "../../../services/title.service";

// other
import { Constants } from "../../../constants/constants";
import {
    ChannelSource,
    Source,
    MediaConnectFlow,
    ChannelTargetBroadcasterPreference,
    Tag
} from "src/app/models/shared";
import { AdaptiveChannel, DeliveryChannel, NdiTarget as UINdiTarget } from "../channel";
import { DeliveryTarget } from "../../../pages/targets/targets.service";
import { SourcePreference } from "@zixi/models";
import { urlBuilder } from "@zixi/shared-utils";
import { AlertingProfile } from "../../configuration/events-management/events-management";

type ChannelForm = FormGroup<{
    tags: FormControl<Tag[]>;
    name: FormControl<string>;
    alertingProfile: FormControl<AlertingProfile>;
    broadcasterClusterId: FormControl<number>;
    sources: FormControl<{ id: number; primary: boolean }[]>;
    muted: FormControl<boolean>;
    isDisable: FormControl<boolean>;
    targetBroadcasterId: FormControl<number>;
}>;

@Component({
    selector: "app-channel-pass-through-form",
    templateUrl: "./channel-form.component.html"
})
export class ChannelFormPassThroughComponent implements OnInit, OnDestroy {
    channel: DeliveryChannel;
    channels: (AdaptiveChannel | DeliveryChannel | MediaConnectFlow)[];
    channelId: number;
    channelName: string;
    channelNames: string[];
    channelCluster: string;

    existingChannel: DeliveryChannel;

    private objectType: string = null;
    private objectCluster: string = null;
    private objectName: string = null;

    private action: string;
    loading = true;
    private _saving = false;

    isFormSubmitted = false;
    isInvalidSubmit = false;
    isEdit = false;
    isClone = false;
    constants = Constants;

    rawSources: Source[] = [];
    sourcesLoading = true;
    sourcesFilter = "";
    sourceSettings: ChannelSource[] = [];

    sourceCluster: boolean;
    sourceClusterAutoPullLock: boolean;
    channelRefreshInitiated = false;
    urlBuilder = urlBuilder;

    page = 1;
    pageSize = 10;

    clusterDNSPrefix: string;

    advanceData = {
        external_id: ""
    };

    private channelsSubscription: Subscription;
    private sourcesSubscription: Subscription;

    private _targetBXsLoading = false;
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private clusterService = inject(ClustersService);
    private sourcesService = inject(SourcesService);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private channelsService = inject(ChannelsService);
    private mixpanelService = inject(MixpanelService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);
    private location = inject(Location);
    private broadcastersService = inject(BroadcastersService);

    targetBXs = [
        { name: this.translate.instant("ANY"), id: ChannelTargetBroadcasterPreference.AnyBroadcaster },
        {
            name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"),
            id: ChannelTargetBroadcasterPreference.PrimaryOnly
        },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: ChannelTargetBroadcasterPreference.BackupOnly }
    ];
    targetBXsBase = this.targetBXs;

    //  Table errors tracking
    haveLackingPrimarySourceError = false;
    haveClusterError = false;
    haveAutoPullLockError = false;

    form: ChannelForm = inject(FormBuilder).group({
        tags: [[] as Tag[], [Validators.required]],
        name: [
            "",
            [
                Validators.required,
                Validators.minLength(2),
                Validators.pattern(Constants.validators.source_name),
                Validators.pattern(Constants.validators.no_blanc_start_or_end)
            ]
        ],
        alertingProfile: [],
        broadcasterClusterId: [0, [Validators.required]],
        sources: [],
        muted: false as boolean,
        isDisable: false as boolean,
        targetBroadcasterId: [0, [Validators.required]]
    });
    haveDisappointedTargets: string[] = [];

    prepForm() {
        if (this.action) {
            if (this.channel) {
                this.channel = this.channel as DeliveryChannel;
                this.form.patchValue({
                    name: this.channel.name,
                    tags: this.channel.resourceTags,
                    alertingProfile: this.channel.alertingProfile,
                    isDisable: this.isEdit ? false : !this.channel.is_enabled,
                    muted: this.action !== "clone" ? false : this?.channel.active_mute ? true : false,
                    broadcasterClusterId: this.channel.broadcaster_cluster_id,
                    targetBroadcasterId: this.channel.target_broadcaster_id
                });

                this.advanceData.external_id = this.channel.external_id || "";

                if (this.channel.sources) {
                    this.sourceSettings = this.channel.sources.map(src => ({
                        id: src.source.id,
                        primary: src.primary,
                        source: this.rawSources.find(rawSrc => rawSrc.id === src.source.id) || src.source
                    }));

                    this.form.controls.sources.setValue(
                        this.sourceSettings.map(source => ({ id: source.source.id, primary: !!source.primary }))
                    );
                }
                if (this.channel.broadcaster_cluster_id !== null) {
                    this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
                    this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);
                }
                if (this.action === "edit") {
                    this.isEdit = true;
                } else if (this.action === "clone") {
                    this.isClone = true;
                    this.form.controls.name.setValue("");
                    this.form.controls.muted.setValue(!!this.channel.active_mute);
                }
            }
        }

        if (!this.channel && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("CHANNEL", this.action, this.channel);
    }

    resetForm() {
        this.form.reset();

        // Channel
        this.channel = new DeliveryChannel();

        // UI
        this.sourceCluster = false;
        this.advanceData.external_id = "";
        this.sourceClusterAutoPullLock = false;
        this.sourceSettings = [];
        this.channel.alt_channel_id = null;
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.channelId = urlBuilder.decode(params.channelId);
        this.channelName = params.name;
        this.channelCluster = params.cluster;
        this.action = params.action;

        this.objectType = params.objecttype;
        this.objectCluster = params.objectcluster;
        this.objectName = params.objectname;

        if (this.channelName && this.channelId) {
            this.channel = Object.assign({}, this.channelsService.getCachedDeliveryChannel(this.channelId));

            this.existingChannel = _.cloneDeep(this.channel);
            // Check if channel found in cache, if not get channels and channel
            if (this.sharedService.isEmptyObject(this.channel) || !this.channel.hasFullDetails) {
                this.channelRefreshInitiated = true;
                const adaptiveChannels = firstValueFrom(this.channelsService.getAdaptiveChannels());
                const deliveryChannels = firstValueFrom(this.channelsService.getDeliveryChannels());
                await Promise.all([adaptiveChannels, deliveryChannels]);
                this.channel = Object.assign({}, this.channelsService.getCachedDeliveryChannel(this.channelId));

                const channel = await this.channelsService.getDeliveryChannel(this.channel.id);
                if (channel) this.channel = Object.assign({}, channel);

                this.existingChannel = _.cloneDeep(this.channel);
            }
        } else {
            this.channelsService.getAdaptiveChannels();
            this.channelsService.getDeliveryChannels();
        }
        this.loading = false;

        // Channels
        if (!this.channelRefreshInitiated) {
            this.channelsService.getAdaptiveChannels();
            this.channelsService.getDeliveryChannels();
        }

        this.channelsSubscription = this.channelsService.channels.subscribe(
            (channels: (AdaptiveChannel | DeliveryChannel | MediaConnectFlow)[]) => {
                this.channels = channels;
                this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
            }
        );

        // Sources
        this.sourcesLoading = true;
        this.sourcesService.refreshSources(true);
        this.sourcesSubscription = this.sourcesService.sources.subscribe(sources => {
            this.rawSources = sources;

            this.fillSourcesData();
            this.check_sources_for_errors();

            this.sourcesLoading = false;
        });

        this.prepForm();
        this.form.controls.broadcasterClusterId.valueChanges.subscribe(broadcasterClusterId => {
            broadcasterClusterId && !this.targetBXsLoading
                ? this.form.controls.targetBroadcasterId.enable()
                : this.form.controls.targetBroadcasterId.disable();
        });
    }

    ngOnDestroy() {
        this.channelsSubscription.unsubscribe();
        this.sourcesSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        this.check_sources_for_errors();
        if (
            this.sourceSettings.length === 0 ||
            this.haveLackingPrimarySourceError ||
            this.haveClusterError ||
            this.haveAutoPullLockError ||
            this.haveDisappointedTargets.length > 0
        ) {
            this.saving = false;
            return;
        }
        this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);

        const formValue = this.form.getRawValue();
        const model = {
            name: formValue.name,
            resource_tag_ids: _.map(formValue.tags, "id"),
            alerting_profile_id: formValue.alertingProfile.id,
            broadcaster_cluster_id: formValue.broadcasterClusterId,
            type: "delivery",
            sources: this.sourceSettings.map(src => ({ primary: !!src.primary, source_id: src.source.id })),
            target_broadcaster_id: formValue.targetBroadcasterId,
            muted: formValue.muted ? 1 : 0,
            is_enabled:
                !this.isEdit && formValue.isDisable
                    ? 0
                    : !this.isEdit && !formValue.isDisable
                    ? 1
                    : this.channel.is_enabled,
            external_id: this.advanceData.external_id || "",
            alt_channel_id: this.channel.alt_channel_id
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingChannel, []);
            const newSources = this.compareSources(this.existingChannel, model);
            if (!newSources) {
                delete changedData["sources"];
            } else {
                Object.assign(changedData, { sources: newSources });
            }
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedChannel = await this.channelsService.updateChannel(this.channel, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedChannel;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "CHANNEL",
                        async () => {
                            const updateAndRestartChannel = await this.channelsService.updateChannel(this.channel, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartChannel) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart pass-through channel", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularChannelUrl(
                                        this.channel.id,
                                        Constants.urls.channelTypes.delivery,
                                        model.name
                                    )
                                );
                            } else {
                                this.isInvalidSubmit = true;
                                this.saving = false;
                            }
                        },
                        model.name
                    );
                    this.saving = false;
                } else if (updatedChannel) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update pass-through channel", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularChannelUrl(
                            this.channel.id,
                            Constants.urls.channelTypes.delivery,
                            model.name
                        )
                    );
                } else {
                    this.isInvalidSubmit = true;
                    this.saving = false;
                }
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(this.channel.id, Constants.urls.channelTypes.delivery, model.name)
                );
            }
        } else {
            const result = await this.channelsService.addChannel(model, model.type);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create pass-through channel");
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(result.id, Constants.urls.channelTypes.delivery, model.name)
                );
            } else {
                this.isInvalidSubmit = true;
                this.saving = false;
            }
        }
    }

    set targetBXsLoading(isLoading: boolean) {
        isLoading || !this.form.controls.broadcasterClusterId.value
            ? this.form.controls.targetBroadcasterId.disable()
            : this.form.controls.targetBroadcasterId.enable();
        this._targetBXsLoading = isLoading;
    }
    get targetBXsLoading() {
        return this._targetBXsLoading;
    }

    get saving() {
        return this._saving;
    }
    set saving(saving: boolean) {
        saving ? this.form.controls.name.disable() : this.form.controls.name.enable();
        this._saving = saving;
    }

    compareSources(existingChannel: DeliveryChannel, model: { sources: { primary: boolean; source_id: number }[] }) {
        const reducedOriginal = _.map(existingChannel.sources, cs => ({
            primary: !!cs.primary,
            source_id: cs.source.id
        }));
        if (_.isEqual(reducedOriginal, model.sources)) {
            return false;
        } else {
            return model.sources;
        }
    }

    back() {
        this.location.back();
    }

    async reloadBroadcasterTargets(broadcasterClusterId: number) {
        this.form.controls.broadcasterClusterId.setValue(broadcasterClusterId);
        this.form.controls.targetBroadcasterId.setValue(0);
        await this.getClusterChannelNames(broadcasterClusterId);
        this.getClusterDNSPrefix(broadcasterClusterId);
    }

    async getClusterChannelNames(id: number) {
        await this.getTargetBroadcasters(id);
        const filteredChannels = _.filter(this.channels, channel => {
            if (this.existingChannel && this.existingChannel.mediaconnect) return channel.mediaconnect;
            else {
                if (channel.adaptive || channel.delivery) return id === channel.broadcaster_cluster_id;
                return false;
            }
        });
        let filteredChannelNames = _.map(filteredChannels, "name");

        if (this.isEdit) filteredChannelNames = _.without(filteredChannelNames, this.channelName);

        this.channelNames = filteredChannelNames;
    }

    async getTargetBroadcasters(clusterId: number) {
        this.targetBXsLoading = true;
        this.targetBXs = this.targetBXsBase;
        if (!clusterId) {
            this.targetBXsLoading = false;
            return;
        }

        try {
            const broadcasters = await this.broadcastersService.refreshBroadcasters(clusterId, true).toPromise();
            if (broadcasters && broadcasters.length > 0) {
                this.targetBXs = this.targetBXsBase.concat(
                    _.map(broadcasters, broadcaster => {
                        return {
                            id: broadcaster.id,
                            name: broadcaster.name,
                            type: "broadcaster",
                            generalStatus: broadcaster.generalStatus
                        };
                    })
                );
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log("error", e);
        }

        this.targetBXsLoading = false;
    }

    getClusterDNSPrefix(id: number) {
        const cluster = this.clusterService.getCachedCluster(null, id);
        if (cluster) this.clusterDNSPrefix = cluster.dns_prefix;
    }

    private fillSourcesData() {
        const selectedSourcesIds: number[] = [];
        for (const srcSet of this.sourceSettings) {
            selectedSourcesIds.push(srcSet.source.id);
            const fullSource = this.rawSources.find(s => srcSet.source.id === s.id);
            srcSet.source = fullSource;
        }

        if (this.objectType && this.objectCluster && this.objectName) {
            const source = this.rawSources.find(
                source => source.name === this.objectName && source.inputCluster.dns_prefix === this.objectCluster
            );

            if (source) {
                if (!selectedSourcesIds.includes(source.id)) {
                    selectedSourcesIds.push(source.id);
                    this.sourceSettings.push({ source, primary: true });
                }

                if (source.broadcaster_cluster_id) {
                    // this.channel.broadcaster_cluster_id = source.broadcaster_cluster_id;
                    this.form.controls.broadcasterClusterId.setValue(source.broadcaster_cluster_id);
                    this.getClusterChannelNames(source.broadcaster_cluster_id);
                    this.getClusterDNSPrefix(source.broadcaster_cluster_id);
                }
            }
        }

        this.rawSources = this.rawSources.filter(src => !selectedSourcesIds.includes(src.id));
    }

    private check_sources_for_errors() {
        this.haveClusterError = false;
        this.haveAutoPullLockError = false;
        this.haveLackingPrimarySourceError = false;
        this.haveDisappointedTargets = [];

        let havePrimarySource = false;
        let haveBackupSources = false;
        this.sourceCluster = false;
        this.sourceClusterAutoPullLock = false;
        const clusterIds: { [key: number]: boolean } = {};
        for (const src of this.sourceSettings) {
            this.sourceCluster = this.sourceCluster || !!src.source?.monitor_only;
            clusterIds[src.source.broadcaster_cluster_id] = true;
            if (src.primary) havePrimarySource = true;
            if (!src.primary) haveBackupSources = true;
            if (src.source.disable_autopull) this.sourceClusterAutoPullLock = true;
        }

        if (this.sourceCluster && Object.keys(clusterIds).length > 1) {
            this.haveClusterError = true;
        }

        if (this.sourceClusterAutoPullLock && Object.keys(clusterIds).length > 1) {
            this.haveAutoPullLockError = true;
        }

        if (this.sourceClusterAutoPullLock || this.sourceCluster) {
            const sortedSources = this.sourceSettings.sort((a: ChannelSource, b: ChannelSource) => {
                const aStickyScore = a.source
                    ? a.source.disable_autopull
                        ? 2
                        : 0 + a.source.monitor_only
                        ? 1
                        : 0
                    : -1;
                const bStickyScore = b.source
                    ? b.source.disable_autopull
                        ? 2
                        : 0 + b.source.monitor_only
                        ? 1
                        : 0
                    : -1;
                return bStickyScore - aStickyScore; //  The stickier the closer to head.
            });

            this.form.controls.broadcasterClusterId.setValue(sortedSources[0].source.broadcaster_cluster_id);
        }

        const allTargets: (DeliveryTarget | UINdiTarget)[] = [
            ...(this.channel.ndi ?? []),
            ...(this.channel.rist ?? []),
            ...(this.channel.rtmpPush ?? []),
            ...(this.channel.srt ?? []),
            ...(this.channel.udpRtp ?? []),
            ...(this.channel.zixiPull ?? []),
            ...(this.channel.zixiPush ?? [])
        ];
        this.haveDisappointedTargets = allTargets
            .filter(t => {
                switch (t.preferred_source) {
                    case SourcePreference.any:
                    case SourcePreference.preferPrimary:
                    case SourcePreference.preferBackup:
                        return false;
                    case SourcePreference.backupOnly:
                        return !haveBackupSources;
                    case SourcePreference.primaryOnly:
                        return !havePrimarySource;
                    default:
                        return !this.sourceSettings.find(s => s.source.id === t.preferred_source);
                }
            })
            .map(t => t.name);

        this.haveLackingPrimarySourceError = !havePrimarySource;
    }

    addSource(source: Source) {
        this.sourceSettings.push({ primary: true, source });
        this.rawSources = this.rawSources.filter(src => src.id !== source.id);
        this.check_sources_for_errors();
    }

    deselectSource(srcSet: ChannelSource) {
        this.sourceSettings = this.sourceSettings.filter(src => src.source.id !== srcSet.source.id);
        this.rawSources.push(srcSet.source);
        this.rawSources = this.sharedService.sort(this.rawSources || [], "name", "asc");
        this.check_sources_for_errors();
    }
}
